import "./style.css";
import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import axios from "axios";
import { BASE_URL } from "../../utils/config";
import BannerWithoutImage from "../../components/BannerWithoutImage/BannerWithoutImage";

const ShopNow = () => {
  const [products, setProducts] = useState([]);
  useEffect(() => {
    getProducts();
  }, []);
  const getProducts = async () => {
    await axios
      .get(`${BASE_URL}product/get_all_products`)
      .then((response) => {
        if (response.status === 200) {
          setProducts(response.data.products);
          console.log(response.data.products);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div>
      <Header />

      {/* SHOP-NOW START */}

      <div className="shop-now">
        <BannerWithoutImage
          firstSection={"Stay refreshed with Al Fajar Water!"}
          secondSection={
            "Shop now for pure hydration and support sustainable practices for a healthier planet."
          }
        />

        {/* BOTTLES START */}
        {products.length > 0 && (
          <div
            id="shop-below"
            className="bottles bg-white pb-12 xs:py-8 lg:py-32"
          >
            <div className="container mx-auto flex justify-evenly items-end flex-wrap space-y-10 lg:space-y-0">
              {products.map((product) => (
                <div
                  key={product._id}
                  className="w-1/2 xs:w-auto bottle-card flex flex-col justify-center items-center"
                >
                  {product.status ? (
                    <a
                      href={`/item/${product._id}`}
                      className="bottle-img-outer bottle-img-5-Gallon"
                    >
                      <img src={product.coverImage} alt={product.name} />
                    </a>
                  ) : (
                    <div className="bottle-img-outer bottle-img-5-Gallon">
                      <img src={product.coverImage} alt={product.name} />
                    </div>
                  )}
                  <p>{product.name}</p>
                  {product.salePrice === 0 ? (
                    <div className="mt-10"></div>
                  ) : (
                    <p>Rs. {product.salePrice}</p>
                  )}
                  <div className="outline-none mt-2">
                    {product.status ? (
                      <a href={`/item/${product._id}`} className="outline-none">
                        <span>Add To Cart</span>
                      </a>
                    ) : (
                      <span>Coming Soon</span>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        {/* BOTTLES ENDS */}
      </div>

      {/* SHOP-NOW END */}

      <Footer />
    </div>
  );
};

export default ShopNow;
